<template>
  <div class="holder">
    <h1>
      表格形态专用图表
      <span @click="playIn1('tableRankChart')">入场</span>
      <span @click="playOut('tableRankChart')">出场</span>
    </h1>
    <table-rank-Chart
      ref="tableRankChart"
      id="tableRankChart"
      customClass="c_line1"
    />
  </div>
</template>
<script>
import tableRankChart from "@/components/marketChart/tableRankChart.vue";
import earnRank from "./earnRank.json";
export default {
  data: function () {
    return {};
  },
  components: { tableRankChart },
  methods: {
    playIn1(refName) {
      this.$refs[refName].tableRankChart(earnRank.data, {
        width: 400,
        height: 500,
        marginLeft: 50,
        marginRight: 80,
        marginTop: 50,
        marginBottom: 0,

        isNegativeSameDirection: true,
        duration: 400,
        delay: 400,
        yPadding: 0.5,
        ease: "easeBack",
        numberPosition: "inside",
        valueSuffix: "%",
        title: ["序号", "个股", "收益"],
      });
    },

    playOut(refName) {
      this.$refs[refName].playOut();
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.holder {
  padding: 20px;
}
h1 {
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: normal;
  color: #333;
  span {
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    background: #999;
    color: #fff;
    user-select: none;
    margin: 0 3px;
  }
  span:nth-of-type(1) {
    background: #447746;
  }
  span:nth-of-type(2) {
    background: #953e23;
  }
}
h2 {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  .new {
    color: #fff;
    background: #f00;
    padding: 0.5rem;
    border-radius: 0.3125rem;
    margin-right: 0.625rem;
  }
}
p {
  margin-bottom: 20px;
}

.c_line1,
.c_line11 {
  margin-bottom: 20px;
  min-height: 200px;
  background: #fff;
  // background: -webkit-linear-gradient(top right, #e5884f, #e16906);
  // background: -moz-linear-gradient(top right, #e5884f, #e16906);
  // background: linear-gradient(to bottom left, #e5884f, #e16906);
  display: inline-block;
  /deep/ .axis_x,
  /deep/ .axis_y {
    text-anchor: start;
    .text {
      // fill: #fff;
      font-size: 14px;
    }
    .domain,
    .tick_line {
      stroke: #fff;
    }
    .tick_long_line {
      display: none;
    }
  }
  /deep/ .bar_negative {
    fill: #47e60e;
  }
  /deep/ .bar_positive {
    fill: #e62c0e;
  }

  /deep/ .value1_text_inside {
    fill: #fff;
  }

  /deep/ .value2_negative {
    fill: #47e60e;
  }

  /deep/ .value2_positive {
    fill: #e62c0e;
  }
  /deep/ .label_name {
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
